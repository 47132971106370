.articlePage {
    padding: 20px;
}

.articleTitle {
    font-size: 24px;
    color: #000000; 
    margin-bottom: 15px;
    text-decoration: none;
}

.articleImage {
    max-width: 100%;
    height: 50vh;
    margin-bottom: 20px;
    padding-top: 4%;
    background-color: #ffffff; 
    border: 1px solid #dddddd; 
    border-radius: 10px; 
}

.articleContent {
    font-size: 16px;
    line-height: 1.5;
}

@media only screen and (max-width: 480px) {

    .articlePage {
        background-color: #e7dece;
        padding: 20px;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    
    .articleTitle {
        font-size: 20px;
    }
    
    .articleImage {
        max-width: 100%;
        height: auto;
        padding-top: 8%;
        background-color: #ffffff; 
        border: 1px solid #dddddd; 
        border-radius: 10px; 
    }
    
    .articleContent {
        font-size: 14px;
        line-height: 1.5;
    }
    
}
