.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--green-color);
    padding: 1em;
    padding-bottom: 3em;
}

.footer img {
    max-width: 3em;
}

.footer div {
    margin-top: 0.7em;
}