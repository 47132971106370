.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  text-decoration: none;
  color: var(--black-color);
}

:link {
  text-decoration: none;
  color: var(--black-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --orange-background : linear-gradient(308deg, rgba(245,169,1,1) 0%, rgba(255,171,81,1) 100%);
  --blue-background : linear-gradient(308deg, rgba(1,178,153,1) 0%, rgba(0,157,152,1) 100%);
  --blue-color : #01B299;
  --green-color : #9EE06F;
  --red-color :#F06532;
  --pink-color : #EDB9AD;
  --orange-color : #F5A901;
  --pale-color : #E7DECE;
  --black-color : #2A272A;
  --shadow : 5px 15px 15px -3px rgba(0,0,0,0.2);
}