.values {
    background: var(--blue-background);
    padding: 1em;
    box-shadow: var(--shadow);
}

.values h1 {
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.valIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.valIcon div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.res>div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    margin: 1em;
}

.cat {
    margin: 0 1em;
}

.cat h2 {
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}